@charset "utf-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-action-edit {
    margin-top: 0;
}

/* Fix for react tailwind datepicker hidden by table's first column */
.table th:first-child {
    z-index: 0
}

.z-10 {
    z-index: 10000;
}

.z-50 {
    z-index: 10000;
}